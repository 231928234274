<template>
	<div>
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;padding: 20px;">
			<div style="">

				<div
					style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;padding-top: 20px;">
					<el-input style="width:270px;" type="text" v-model="form.mobile" auto-complete="off"
						:disabled="true">
						<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
					</el-input>
				</div>

				<div
					style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<div>新密码</div>
					<a style="color: red;">*</a>
				</div>
				<div
					style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<el-input style="width:270px;" type="password" v-model="form.pword" auto-complete="off">
						<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
					</el-input>
				</div>
			</div>

			<div style="">
				<div
					style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<div>确认新密码</div>
					<a style="color: red;">*</a>
				</div>
				<div style="display: flex;width: 100%;align-items: center;justify-content: center;padding-bottom: 20px;">
					<el-input style="width:270px;" type="password" v-model="form.confirmpwd" auto-complete="off">
						<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
					</el-input>
				</div>
			</div>

			<!-- 验证码 -->
			<div style="display: flex;width: 100%;align-items: center;justify-content: center;">
				<el-input style="width:150px;margin-right: 10px;" v-model="form.smscode" type="text" auto-complete="off"
					:placeholder="$t('register.right.smscode')">
					<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
				</el-input>
				<el-button v-bind:class="{grey:isGrey,blue:!isGrey}" v-bind:disabled="dis" type="primary"
					@click="getCode">
					<span v-if="show">{{$t('register.right.smscodeButton1')}}</span>
					<span v-else>{{$t('register.right.smscodeButton2')}}({{count}}s)</span>
				</el-button>
			</div>

			<!-- <wangEditor v-model="wangEditorDetail" :isClear="isClear" @change="wangEditorChange"></wangEditor> -->
			<!-- <button v-print="printOption">NB打印</button>
			<div id="nbprint">
						<table>
							<tr>
								<th>序号</th>
								<th>姓名</th>
								<th>年龄</th>
								<th>性别</th>
								<th>手机</th>
							</tr>
							<tr v-for="(item, index) in list" key="index">
								<td>{{ index + 1}}</td>
								<td>{{ item.name }}</td>
								<td>{{ item.age }}</td>
								<td>{{ item.sex }}</td>
								<td>{{ item.phone }}</td>
							</tr>
						</table>
					</div> -->

			<!-- <kr-print-designer :temp-value="value" :widget-options="widgets" @save="handleSave" /> -->

			<div class="bottom">
				<c-button type="warning" :height="33" style="margin-left: 10px;" @click="changePassword">保存</c-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getUser,
		updateAdd,
		updatepword
	} from '@/api/userInfo/index'
	import {
		getCode,
	} from '@/api/home/index.js'
	import Cookies from "js-cookie";
	import CButton from '@/components/CButton/index.vue' //button组件引入
	// import wangEditor from "@/components/WangEditor/index.vue";//引入富文本插件
	export default {
		components: {
			CButton,
			// wangEditor
		},
		data() {
			return {
				userInfo: null, //获取用户信息接口
				// 表单参数
				form: {
					id: undefined, //用户编号
					qq: undefined, //qq号码
					email: undefined, //邮箱
					uid: undefined, //用户编号
					pword: undefined, //新密码
					confirmpwd: undefined, //确认密码
					smscode: undefined, //验证码
					mobile: undefined, //手机号
				},
				// isClear: false,//设置为true的时候，这个可以用this.wangEditorDetail=''来替代
				// wangEditorDetail: "",
				dis: false,
				show: true,
				isGrey: true, //按钮样式
				timer: null, //设置计时器,
				count: "",

				// printOption: {
				// 	id: 'nbprint', // 打印元素的id 不需要携带#号
				// 	// preview: true, // 开启打印预览
				// 	// previewTitle: '打印预览', // 打印预览标题
				// 	popTitle: '员工信息', // 页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
				// 	// 头部文字 默认空 在节点中添加 DOM 节点， 并用,(Print local range only)分隔多个节点
				// 	// extraHead:'https://***/***.css, https://***/***.css', 
				// 	// 新的 CSS 样式表， 并使用,（仅打印本地范围）分隔多个节点
				// 	// extraCss: '<meta http-equiv="Content-Language"content="zh-cn"/>',
				// 	previewBeforeOpenCallback: () => {
				// 		console.log("触发打印预览打开前回调");
				// 	},
				// 	previewOpenCallback: () => {
				// 		console.log("触发打开打印预览回调");
				// 	},
				// 	beforeOpenCallback: () => {
				// 		console.log("触发打印工具打开前回调");
				// 	},
				// 	openCallback: () => {
				// 		console.log("触发打开打印工具回调");
				// 	},
				// 	closeCallback: () => {
				// 		console.log("触发关闭打印工具回调");
				// 	},
				// 	clickMounted: () => {
				// 		console.log("触发点击打印回调");
				// 	}
				// },
				// list: [{
				// 		name: "阿哒",
				// 		age: 26,
				// 		sex: "男",
				// 		phone: "12345678901",
				// 	},
				// 	{
				// 		name: "阿荣",
				// 		age: 24,
				// 		sex: "男",
				// 		phone: "12345678901",
				// 	}
				// ],
				// value: {
				// 	title: 'demo',
				// 	width: 750,
				// 	height: 550,
				// 	pageWidth: 750,
				// 	pageHeight: 550,
				// 	tempItems: [],
				// },
				// widgets: [{
				// 		type: "braid-txt",
				// 		isEdit: true,
				// 		title: "自定义文本",
				// 		value: "自定义文本",
				// 		defaultValue: "自定义文本"
				// 	},
				// 	{
				// 		type: "braid-txt",
				// 		isEdit: false,
				// 		title: "公司名称",
				// 		value: "{公司名称}",
				// 		defaultValue: "九州科瑞",
				// 		name: "companyName"
				// 	},
				// 	{
				// 		type: "bar-code",
				// 		title: "订单编号（条码）",
				// 		value: "{orderNumber}",
				// 		defaultValue: "1234567890",
				// 		name: "orderNumber",
				// 		lodopStyle: {
				// 			QRCodeVersion: '1',
				// 			QRCodeErrorLevel: 'L'
				// 		},
				// 	},
				// 	{
				// 		type: "braid-html",
				// 		title: "分页",
				// 		value: "{第##页/共##页}",
				// 		defaultValue: "<font ><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>"
				// 	},
				// 	{
				// 		type: "braid-image",
				// 		title: "logo",
				// 		value: "http://192.168.7.229/syy/file/files/view/5f9fb42dabebb4049ffbd019"
				// 	},
				// 	{
				// 		type: "braid-table",
				// 		title: "商品明细",
				// 		name: "details",
				// 		value: "{details}",
				// 		defaultValue: [{
				// 				productName: "苹果ipone11pro",
				// 				skuName: "iphone11pro256g",
				// 				specModel: "165L",
				// 				quantity: 3,
				// 				price: "12.00"
				// 			},
				// 			{
				// 				productName: "苹果ipone11pro",
				// 				skuName: "iphone11pro124g",
				// 				specModel: "165L",
				// 				quantity: 3,
				// 				price: "12.00"
				// 			}
				// 		],
				// 		columnsAttr: [{
				// 				title: "产品名称",
				// 				value: "{产品名称}",
				// 				name: "productName"
				// 			},
				// 			{
				// 				title: "sku名称",
				// 				value: "{sku名称}",
				// 				name: "skuName"
				// 			},
				// 			{
				// 				title: "规格型号",
				// 				value: "{规格型号}",
				// 				name: "specModel"
				// 			},
				// 			{
				// 				title: "数量",
				// 				value: "{数量}",
				// 				name: "quantity"
				// 			},
				// 			{
				// 				title: "单价",
				// 				value: "{单价}",
				// 				name: "price"
				// 			}
				// 		]
				// 	}
				// ],
			}
		},

		created() {
			this.userInfo = Cookies.get("userInfo2")
			if (this.userInfo) {
				this.userInfo = JSON.parse(this.userInfo)
				this.form.mobile = this.userInfo.contacttel
			}
			this.getUser()
		},

		mounted() {
			// this.wangEditorDetail = "我是默认值"; //设置富文本框默认显示内容
		},

		watch: {

		},

		computed: {

		},

		methods: {
			//--------------获取用户信息数据----------------------
			getUser: function() {
				let data = {
					id: this.userInfo.id
				}
				getUser(data).then(res => {
					this.userInfo = res.data
					this.form.qq = res.data.qq
					this.form.email = res.data.email
					this.form.id = res.data.id
					this.form.uid = res.data.id
					sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo))
				})
			},

			//-------------------验证码计时----------------------------------
			getCode: function() {
				let data = {
					mobile: this.form.mobile
				}
				getCode(data).then(res => {
					// console.log(res.code)
					if (res.code == "200") {
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.isGrey = true;
							this.show = false;
							this.dis = true;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.dis = false;
									this.isGrey = true;
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.data.msg);
					}
				})
			},

			//-----------------------修改密码------------------------
			changePassword: function() {
				console.log(this.form.pword.length)
				if (this.form.pword == undefined) {
					this.$message.error('请输入新密码')
				} else if (this.form.pword.length < 8 || this.form.pword.length >12) {
					this.$message.error('请输入8-12位的密码')
				} else if (this.form.confirmpwd == undefined) {
					this.$message.error('请输入再次输入新密码')
				} else if (this.form.pword !== this.form.confirmpwd) {
					this.$message.error('请再次输入的密码和新密码一致')
				} else if (this.form.smscode == undefined) {
					this.$message.error('请输入验证码')
				} else {
					updatepword(this.form).then(res => {
						if(res.code=="200"){
							this.form.pword = undefined
							this.form.confirmpwd = undefined
							this.form.smscode = undefined
							this.$message.success('修改密码成功')
						}else{
							this.$message.error(res.data.msg)
						}
					})
				}
			},

			// wangEditorChange(val) {
			//             console.log("我是富文本的内容",val);
			//             }
			
			// handleSave(e){
			// 	console.log("保存",e)
			// }
		}
	}
</script>

<style lang="less">
	/deep/ .ant-tabs-ink-bar {
		background-color: #f2ab15 !important;
	}

	/deep/ .ant-tabs-tab:hover {
		color: #f2ab15 !important;
	}

	/deep/ .ant-tabs-bar {
		margin: 0 !important;
	}

	/deep/ .ant-tabs-tab-active {
		color: #f2ab15 !important;
	}

	.user {
		display: flex;
		padding: 10px 0;
	}

	.bottom {
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	// @page {
	// 	size: auto;
	// 	margin: 0mm;
	// }
</style>